.resource-card {
    border: 1px solid $lightgray;
    border-radius: 4px;
    margin: 16px;
    box-shadow: 0px 0px 10px -5px $black;
    cursor: pointer;
    max-width: 300px;
    min-width: 300px;
    max-height: 425px;
    @include down($largest) {
        max-width: calc(20% - 32px);
        min-width: calc(20% - 32px);
        min-height: 352px;
    }
    @include down($very-large) {
        max-width: calc(25% - 32px);
        min-width: calc(25% - 32px);
    }
    @include down($large) {
        max-width: calc(33.33% - 32px);
        min-width: calc(33.33% - 32px);
    }
    @include down($large-tablet) {
        max-width: calc(50% - 32px);
        min-width: calc(50% - 32px);
        display: flex;
        flex-direction: column;
    }
    @include down($mobile-xl) {
        width: 100%;
        max-width: 350px;
        margin-left: 0;
        margin-right: 0;
    }
    @include down($mobile) {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .resource-img {
        width: 100%;
        height: 100%;
        max-height: 185px;
        max-width: 100%;
        border-bottom: 0.5px solid $lightgray;
        border-radius: 4px 4px 0 0;
        padding: 16px;
        @include down($mobile-xl) {
            max-height: 200px;
        }

        // Basic Score Sheet
        &.resource-img-1 {
            background: $lavender;
        }

        // Guitar 101
        &.resource-img-2 {
            background: $lightgray;
        }

        // Contract for Hire Score Sheet
        &.resource-img-3 {
            background: $macaroni;
        }
    }

    .copy-container {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 185px);
        min-height: 165px;
        @include down($tablet) {
            height: calc(100% - 160px);
        }
    }

    .resource-name {
        margin-bottom: 8px;
        font-size: 14px;
        @include down($tablet) {
            font-size: 12px;
        }
    }

    .resource-description {
        color: $gray;
        line-height: 1.35;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 14px;
        @include down($tablet) {
            font-size: 12px;
        }
    }

    .bottom-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

